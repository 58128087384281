import {combineReducers} from 'redux';
import fuse from './fuse';
import auth from 'auth/store/reducers';
import notes from './notes.reducer';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        notes,
        ...asyncReducers
    });

export default createReducer;
