import ForgotPassword from './ForgotPassword';
import { authRoles } from 'auth';

export const ForgotPasswordConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/auth/forgot-password',
            component: ForgotPassword
        }
    ]
};

