import { FuseLoadable } from "@fuse";

export const AgentAdminConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/app/agentPanel/agentPanelActivateAgent",
      component: FuseLoadable({
        loader: () => import("./Forms.js")
      })
    }
  ]
};
