import React, { Component } from "react";
import {
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "./store/actions/index";
import { FuseScrollbars } from "@fuse";
import moment from "moment";
import withReducer from "store/withReducer";
import reducer from "./store/reducers";
import history from "../../../src/history";
import TransferMoney from './TransferMoney';
import AddTransaction from './AddTransaction';

// #START 5041 - Liability Adjustments / outstanding liabilities - increased panel width according text
const styles = theme => ({
  root: {
    width: 300 
  }
});

class QuickPanel extends Component {
  state = {
    checked: ["notifications"],
    openTransfer: false,
    openLedger: false,
    path: '',
    isTransferMoney: false,
    isOpenLedger: false
  };

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };

  componentDidMount() {
    this.props.getQuickPanelData();
  }

  openClientList = () => {
    const {toggleQuickPanel} = this.props;
    history.push('/app/bankRec/clientList');
    toggleQuickPanel(false);
  }

  openNewLedger = () => {
    const {toggleQuickPanel} = this.props;
    history.push('/app/bankRec/newLedger');
    toggleQuickPanel(false);
  }

  // #START 5041 - Liability Adjustments / outstanding liabilities
  openOutstandingLiabilities = () => {
    const { toggleQuickPanel } = this.props;
    history.push('/app/bankRec/outstandingLiabilities');
    toggleQuickPanel(false);
  }
  // #END 5041

  openRematchTrans = () => {
    const {toggleQuickPanel} = this.props; history.push('/app/bankRec/RematchTrans'); toggleQuickPanel(false);
  }

  openImportLogs = () => {
    const {toggleQuickPanel} = this.props;
    history.push('/app/bankRec/importLogs');
    toggleQuickPanel(false);
  }

  openTransferMoney = () => {
    this.setState({open: true});
  }

  closeTransferMoney = () => {
    this.setState({open: false});
  }

  openLedger = () => {
    this.setState({openLedger: true});
  }

  closeLedger = () => {
    this.setState({openLedger: false});
  }

  openWarningErrorLogs = () => {
    const {toggleQuickPanel} = this.props;
    history.push(`/app/bankRec/FileImportActivity`);
    toggleQuickPanel(false);
  }

  render() {
    const { classes, state, toggleQuickPanel, data, bankrecId } = this.props;
    const {open, openLedger} = this.state;
    return (
      <>
      <Drawer
        classes={{ paper: classes.root }}
        open={state}
        anchor="right"
        onClose={() => toggleQuickPanel(false)}
      >
        <FuseScrollbars>
         {!bankrecId ? <>
         <ListSubheader component="div">Today</ListSubheader>
          <div className="mb-0 py-16 px-24">
            <Typography className="mb-12 text-32" color="textSecondary">
              {moment().format("dddd")}
            </Typography>
            <div className="flex">
              <Typography
                className="leading-none text-32"
                color="textSecondary"
              >
                {moment().format("DD")}
              </Typography>
              <Typography
                className="leading-none text-16"
                color="textSecondary"
              >
                th
              </Typography>
              <Typography
                className="leading-none text-32"
                color="textSecondary"
              >
                {moment().format("MMMM")}
              </Typography>
            </div>
          </div>
          <Divider />
          <List>
            <ListSubheader component="div">Events</ListSubheader>
            {data &&
              data.events.map(event => (
                <ListItem key={event.id}>
                  <ListItemText
                    primary={event.title}
                    secondary={event.detail}
                  />
                </ListItem>
              ))}
          </List>
          <Divider />
          <List>
            <ListSubheader component="div">Notes</ListSubheader>
            {data &&
              data.notes.map(note => (
                <ListItem key={note.id}>
                  <ListItemText primary={note.title} secondary={note.detail} />
                </ListItem>
              ))}
          </List>
          <Divider />
          <List>
            <ListSubheader component="div">Quick Settings</ListSubheader>
            <ListItem>
              <ListItemIcon>
                <Icon>notifications</Icon>
              </ListItemIcon>
              <ListItemText primary="Notifications" />
              <ListItemSecondaryAction>
                <Switch
                  color="primary"
                  onChange={this.handleToggle("notifications")}
                  checked={this.state.checked.indexOf("notifications") !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Icon>cloud</Icon>
              </ListItemIcon>
              <ListItemText primary="Cloud Sync" />
              <ListItemSecondaryAction>
                <Switch
                  color="secondary"
                  onChange={this.handleToggle("cloudSync")}
                  checked={this.state.checked.indexOf("cloudSync") !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Icon>brightness_high</Icon>
              </ListItemIcon>
              <ListItemText primary="Retro Thrusters" />
              <ListItemSecondaryAction>
                <Switch
                  color="primary"
                  onChange={this.handleToggle("retroThrusters")}
                  checked={this.state.checked.indexOf("retroThrusters") !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          </> :
          <>
            <List>
              <ListSubheader component="div">Bank Rec Tasks</ListSubheader>
              <ListItem>
                <ListItemIcon>
                  <Icon style={{cursor:'pointer'}} onClick={(evt)=> this.openClientList(evt)}>people</Icon>
                </ListItemIcon>
                <ListItemText primary="Customer List" style={{cursor:'pointer'}} onClick={(evt)=> this.openClientList(evt)}/>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon style={{cursor:'pointer'}} onClick={evt=> this.openNewLedger(evt)}>search_icon</Icon>
                </ListItemIcon>
                <ListItemText primary="Search Ledger" style={{cursor:'pointer'}} onClick={evt=> this.openNewLedger(evt)}/>
              </ListItem>
              {/* #START 5041 - Liability Adjustments / outstanding liabilities - bank task new menu*/}
              <ListItem>
                <ListItemIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ cursor: 'pointer' }} onClick={evt => this.openOutstandingLiabilities(evt)}><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 1 0 0 4m0-4a2 2 0 1 1 0 4m-6 8a2 2 0 1 0 0-4m0 4a2 2 0 1 1 0-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 1 0 0-4m0 4a2 2 0 1 1 0-4m0 4v2m0-6V4" /></svg>
                </ListItemIcon>
                <ListItemText primary="Outstanding Liabilities" style={{ cursor: 'pointer' }} onClick={evt => this.openOutstandingLiabilities(evt)} />
              </ListItem>
              {/* #END 5041 */}
              <ListItem>
                <ListItemIcon>
                  <Icon style={{cursor:'pointer'}} onClick={evt=> this.openTransferMoney(evt)}>attach_money</Icon>
                </ListItemIcon>
                <ListItemText primary="Transfer Money" style={{cursor:'pointer'}} onClick={evt=> this.openTransferMoney(evt)}/>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon style={{cursor:'pointer'}} onClick={evt=> this.openLedger(evt)}>add_circle</Icon>
                </ListItemIcon>
                <ListItemText primary="Add Transaction" style={{cursor:'pointer'}} onClick={evt=> this.openLedger(evt)}/>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon style={{cursor:'pointer'}} onClick={evt=> this.openRematchTrans(evt)}>360</Icon>
                </ListItemIcon>
                <ListItemText primary="Re-match" style={{cursor:'pointer'}} onClick={evt=> this.openRematchTrans(evt)}/>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon style={{cursor:'pointer'}} onClick={evt=> this.openWarningErrorLogs(evt)}>note_alt</Icon>
                </ListItemIcon>
                <ListItemText primary="Activity Log" style={{cursor:'pointer'}} onClick={evt=> this.openWarningErrorLogs(evt)}/>
              </ListItem>
              {typeof bankrecId === 'boolean' && <ListItem>
                <ListItemIcon>
                  <Icon style={{cursor:'pointer'}} onClick={evt=> this.openImportLogs(evt)}>undo</Icon>
                </ListItemIcon>
                <ListItemText primary="Un-Import Files" style={{cursor:'pointer'}} onClick={evt=> this.openImportLogs(evt)}/>
              </ListItem>}
            </List>
            {
              open &&
              <TransferMoney 
              openDialogTransfer={open}
              closeTransferMoney={this.closeTransferMoney}
              />
            }
            {
              openLedger &&
              <AddTransaction 
              openDialogLedger={openLedger}
              closeLedger={this.closeLedger}
              />
            }
          </>
          }
        </FuseScrollbars>
      </Drawer>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleQuickPanel: Actions.toggleQuickPanel,
      getQuickPanelData: Actions.getQuickPanelData
    },
    dispatch
  );
}

function mapStateToProps({ auth, quickPanel }) {
  return {
    state: quickPanel.state,
    data: quickPanel.data,
    bankrecId: quickPanel.bankrecId,
  };
}

export default withReducer(
  "quickPanel",
  reducer
)(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(QuickPanel)
  )
);
