import {FuseLoadable} from '@fuse';

export const AnalyticsDashboardAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/dashboards/analytics',
            component: FuseLoadable({
                loader: () => import('./AnalyticsDashboardApp')
            })
        },
        {
            path     : '/apps/dashboards/RPHealthStatus',
            component: FuseLoadable({
                loader: () => import('./AnalyticsDashboardApp')
                // i have commented out this component './RPHealthStatusApp'
            })
        },
        {
            path     : '/apps/dashboards/RPReconciliation',
            component: FuseLoadable({
                loader: () => import('./AnalyticsDashboardApp')
                    // i have commented out this component './RPReconciliationApp'
            })
        }, 
        {
            path     : '/apps/dashboards/RPConversion',
            component: FuseLoadable({
                loader: () => import('./AnalyticsDashboardApp')
                 // i have commented out this component './RPConversionApp'
            })
        },
        {
            path     : '/apps/Home',
            component: FuseLoadable({
                loader: () => import('./SuperUserApp')
                
            })
        },


    ]
};



// /apps/dashboards/health2