//import FuseLoadable
import { FuseLoadable } from "@fuse";
//export ImplementationConfig
export const ManageConfig = {
  //settings
  settings: {
    //set layout
    layout: {
      //set config as empty object
      config: {}
    }
  },
  //set routes
  routes: [
    {
      path     : '/app/management/manage',
      component: FuseLoadable({
          loader: () => import('./ManageBoard')
      })
  }
  ]
  //end routes
};
