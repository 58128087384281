export const IS_FILE_IMPORTING = 'IS_FILE_IMPORTING';
export const IS_SWITCHING_TENANT = 'IS_SWITCHING_TENANT';

export const isFileImport = () => {
    return dispatch => {
        dispatch({
            type: IS_FILE_IMPORTING,
            payload: IS_FILE_IMPORTING
        });
    };
}

export const isSwitchingTenantImport = () => {
    return dispatch => {
        dispatch({
            type: IS_SWITCHING_TENANT,
            payload: IS_SWITCHING_TENANT
        });
    };
}

