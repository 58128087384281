//import React 
import React from "react";
//import FuseNavVerticalCollapse
import FuseNavVerticalCollapse from "./FuseNavVerticalCollapse";
//import FuseNavVerticalItem from "./FuseNavVerticalItem";
//import WithStyles
import { ListSubheader, withStyles } from "@material-ui/core";
//import withRouter
import { withRouter } from "react-router-dom";
//import classNames
import classNames from "classnames";
//import Proptypes
import PropTypes from "prop-types";
//import connect from react-redux
import { connect } from "react-redux";

//declare propTypes
const propTypes = {
  item: PropTypes.shape({
    //set id to required
    id: PropTypes.string.isRequired,
    //set title of type string
    title: PropTypes.string,
    //set children of type array
    children: PropTypes.array
  })
};
//set defaultProps to empty object
const defaultProps = {};
//declare styles
const styles = theme => ({
  item: {
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingRight: 12
  }
});
//define function FuseNavVerticalGroup
const FuseNavVerticalGroup = ({
  classes,
  item,
  nestedLevel,
  userRole,
  active
}) => {
  if (
    item.auth &&
    (!item.auth.includes(userRole) ||
      (userRole !== "guest" &&
        item.auth.length === 1 &&
        item.auth.includes("guest")))
  ) {
    //return null
    return null;
  }
  //set paddingValue based on nestedLevel
  let paddingValue = 40 + nestedLevel * 16;
  //set listItemPadding based on nestedLevel
  const listItemPadding =
    nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-24";
  let listing = item;
  //Return ListSubheader
  return (
    <React.Fragment>
      <ListSubheader
        disableSticky={true}
        className={classNames(
          classes.item,
          listItemPadding,
          "list-subheader flex items-center"
        )}
      >
        <span className="list-subheader-text uppercase text-12">
          {/* {item.title}  */}
          SERVICES
        </span>
      </ListSubheader>
      {
        <React.Fragment>
          {// item.children.map((item) => (
          listing.map(item => (
            <React.Fragment key={item.id}>
              {/* {item.type === 'group' && (
                                    <NavVerticalGroup item={item} nestedLevel={nestedLevel} active={active}/>
                                )} */}

              {/* {item.type === 'collapse' && (
                                    <FuseNavVerticalCollapse item={item} nestedLevel={nestedLevel} active={active}/>
                                )} */}

              <FuseNavVerticalCollapse
                item={item}
                nestedLevel={nestedLevel}
                active={active}
              />

              {/* {item.type === 'item' && (
                                    <FuseNavVerticalItem item={item} nestedLevel={nestedLevel} active={active}/>
                                )} */}
            </React.Fragment>
          ))}
        </React.Fragment>
      }
    </React.Fragment>
  );
}
//define mapStateToProps
let mapStateToProps = ({ auth }) => {
  //return user role
  return {
    userRole: auth.user.role
  };
}
//set proptypes on FuseNavVerticalGroup
FuseNavVerticalGroup.propTypes = propTypes;
//set defaultProps on FuseNavVerticalGroup
FuseNavVerticalGroup.defaultProps = defaultProps;
//connect mapStateToProps to FuseNavVerticalGroup Component
const NavVerticalGroup = withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps)(FuseNavVerticalGroup))
);
//export default class component
export default NavVerticalGroup;
