import * as Actions from '../actions'

const initialState = {
    IS_FILE_IMPORTING: false,
    IS_SWITCHING_TENANT: false
};

const fileImport = function (state = initialState, action) {
    switch ( action.type ){
        case Actions.IS_FILE_IMPORTING:{
            return {
                ...state,
                IS_FILE_IMPORTING: action.payload
            };
        }
        case Actions.IS_SWITCHING_TENANT:{
            return {
                ...state,
                IS_SWITCHING_TENANT: action.payload
            };
        }
        default:{
            return state;
        }
    }
};

export default fileImport;
