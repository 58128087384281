import { authRoles } from 'auth';
//import Login from './Login';
//import Mfa from './MfaUIComponent';
import {FuseLoadable} from '@fuse';

export const LoginConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        // {
        //     path: '/login',
        //     component: Login
        // },
        // {
        //     path: '/login/mfa',
        //     component: Mfa
        // },
        {
            path     : '/login',
            component: FuseLoadable({
                loader: () => import('./Login')
            })
        }
    ]
};

