import {FuseLoadable} from '@fuse';

export const M3HealthstatusConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/millenniumiaas/M3Healthstatus',
            component: FuseLoadable({
                loader: () => import('../../dashboards/analytics/AnalyticsDashboardApp')
              
            })
        },
      
       
      

    ]
};

