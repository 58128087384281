import React from 'react';
//import withStyles
import {withStyles} from '@material-ui/core/styles/index';
//import withRouter
import {withRouter} from 'react-router-dom';
//import classNames
import classNames from 'classnames';
import { connect } from 'react-redux';
// import {Typography} from '@material-ui/core';
//set styles
const styles = theme => ({
    root      : {
        
        display                       : 'flex',
        alignItems                    : 'center',
        '& .logo-text, & .react-badge': {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
                easing  : theme.transitions.easing.easeInOut
            })
        }
    },
    logo      : {},
    logoIcon  : {
        
        width     : 208,
        height    : 45, // S_5029 - logo changes - add change of height to increase top & bottom margin
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing  : theme.transitions.easing.easeInOut
        })
    },
    reactBadge: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color          : '#fdfdfd'
    }
});
//define MainNavbarHeader
const MainNavbarHeader = ({classes, settings, navbar}) => {
    const {foldedOpen} = navbar;
    const {folded} = settings.layout.config.navbar;
    return (
        <div className={classes.root}>
            <div className={classNames(classes.logo, "flex items-center")}>
                <img className={classNames(classes.logoIcon, "logo-icon")} src={!foldedOpen && folded ? "assets/images/logos/Assure-circle.png" : "assets/images/logos/assure-black-no-rake.svg"} alt="logo"/>
                {/* <Typography className="text-16 ml-8 font-light logo-text" color="inherit">FUSE</Typography> */}
                {/* <div className={classNames(classes.reactBadge, "react-badge flex items-center ml-12 mr-8 py-4 px-8 rounded")}>
                    <img
                        className="react-logo"
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K"
                        alt="react"
                        width="16"
                    />
                    <span className="react-text text-12 ml-4">React</span>
                </div> */}
            </div>
        </div>
    );
}

let mapStateToProps = function({ fuse }) {
    return {
        settings: fuse.settings.current,
        navbar: fuse.navbar
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, null)(MainNavbarHeader)));
