import React , { createRef } from "react";
import PropTypes from "prop-types";
import "./autocomplete.css";
import "./MoneyTransfer.css"
import { Icon, TextField, InputAdornment} from "@material-ui/core";
import formatCurrency from 'format-currency';

class Autocomplete extends React.Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);
    //START: Bug 5010 Search Customer Bug
    this.dropDownRef = createRef();
    this.closeDropDown = this.closeDropDown.bind(this);
    //END: Bug 5010 Search Customer Bug
    this.state = {
      // The active selection's index
      activeSuggestion: -1,
      // The suggestions that match the user's input
      clientfiltSuggestions: [],
      // Whether or not the suggestion list is shown
      clientListshowSuggestions: true,
      // What the user has entered
      userClientInput: "",
      //initial input
      usrClientInput: "",
      //onclick flag
      flag: false
    };
  }

  convertUptoTwoDecimal = number => {
    if (number) {
        return formatCurrency(number.toFixed(2));
    }
    else {
        return number;
    }
  }
/* 
  1. Lifecycle method of react to check if props passed from parent
  component contains the value
  2. If variable holds the value then it will check if company is present in the company
  list and set the state of variable userClientInput
*/
  static getDerivedStateFromProps(nextProps, prevState) {
    const {money, coName, clientCoName, populateco, secondFlag, autoCoFlag} = nextProps;
    const {flag} = prevState;
    if((money || populateco) && coName.trim() && (flag || secondFlag)) {
      let matcher = new RegExp(`^${coName.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, "\\$&")}`, 'g');
      const finalDervCol = !flag ? clientCoName.filter(dervsugg => dervsugg.toLowerCase().startsWith(coName.toLowerCase()))
      : clientCoName.filter(word => word && coName && word.match(matcher));
      return { userClientInput: Array.isArray(finalDervCol) ? finalDervCol[0] : finalDervCol}
    }
    if(typeof autoCoFlag === 'boolean') {
      let finalObj = {};
      finalObj = flag && autoCoFlag ? {flag: false} : {};
      if(!autoCoFlag && !flag)
        finalObj = {userClientInput:''};
      return {...finalObj}
    }
    return null;
  }

  componentDidMount() {
    const {coName: userClientInput} = this.props;
    this.setState({userClientInput});
    //START: Bug 5010 Search Customer Bug: Register one event for click away listener...
    document.addEventListener("mousedown", this.closeDropDown);
    //END: Bug 5010 Search Customer Bug
  }

  //START: Bug 5010 Search Customer Bug : De-Register one event for click away listener...
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeDropDown);
  }

  closeDropDown(e) {
    const {clientfiltSuggestions} = this.state;
    if (
      this.state.clientListshowSuggestions &&
      this.dropDownRef.current &&
      !this.dropDownRef.current.contains(e.target)
    ) {
      if(clientfiltSuggestions.length !== 1 && clientfiltSuggestions[clientfiltSuggestions.length -1] !== 'Add New') {
        this.setState({
          activeSuggestion: 0,
          clientfiltSuggestions: [],
          clientListshowSuggestions: false,
        });
      }
    }
  }
  //END: Bug 5010 Search Customer Bug

  filterOnChangeKeyPress = (from, userClientInput) => {
    const {clientCoName} = this.props;
    let clientfiltSuggestions = [];
    if(from === "onChange" || from === "onKeyDown") {
            clientfiltSuggestions = clientCoName.filter(
              suggestion =>
                suggestion.toLowerCase().indexOf(userClientInput.toLowerCase()) > -1
            );
            clientfiltSuggestions = ['Add New', ...clientfiltSuggestions];
            this.setState({
              activeSuggestion: -1,
              clientfiltSuggestions,
              clientListshowSuggestions: true,
              userClientInput,
              usrClientInput: userClientInput,
              flag: true
            });
      }
  }

  onChange = e => {
    const userClientInput = e.currentTarget.value;
    this.filterOnChangeKeyPress("onChange",userClientInput);
  };

  onClick = (e, userClientInput) => {
    const {onSelectChange, toInd, money, coTxt} = this.props;
    const obj = money ? {toInd} : {};
    const clickIndTxt = money && typeof toInd === 'number' ? toInd: '';
    if(money) {
      const onUnClickTab = document.getElementById(`MoneyDropDown${clickIndTxt}`);
      onUnClickTab.focus();
    } else {
      const onUnClickAddTab = document.getElementById(`ErrorDropDown${toInd ? toInd: ''}`);
      onUnClickAddTab.focus();
    }
    this.setState({
      activeSuggestion: 0,
      clientfiltSuggestions: [],
      clientListshowSuggestions: false,
      userClientInput,
      flag: true
    }, ()=>{
        onSelectChange(coTxt ? coTxt : 'coName', userClientInput, obj);
    });
  };

  scrollList = suggestion =>{
    const {money} = this.props;
    var list  = money ? document.querySelector('.moneysuggest-active') : document.querySelector('.suggestion-active');
    var ullist  = money? document.querySelector('.moneysuggest') : document.querySelector('.suggestions');
  
    var elHeight = list.clientHeight;
    var scrollTop = ullist.scrollTop;
    var viewport = scrollTop + ullist.clientHeight;
    var elOffset = elHeight * suggestion;
    
    if (elOffset < scrollTop || (elOffset + elHeight) > viewport) {
      ullist.scrollTop=elOffset;
    }
}

  onKeyDown = e => {
    const {money} = this.props;
    let downlist  = money ? document.querySelector('.moneysuggest') : document.querySelector('.suggestions');
    const { activeSuggestion, clientfiltSuggestions, userClientInput, clientListshowSuggestions} = this.state;

    if (e.keyCode === 13 && e.target.value.trim()!=='' && clientfiltSuggestions.length) {
      if(clientListshowSuggestions && activeSuggestion!==-1) {
            const suggest = clientfiltSuggestions[activeSuggestion];
            if(suggest === 'Add New') {
              this.openClientDialog('', userClientInput)
            } else {
              this.onClick('', suggest);
            }
      }
    }
    // User pressed the up arrow
    else if (e.keyCode === 38 && e.target.value.trim()!=='') {
     if(this.state.clientListshowSuggestions && activeSuggestion!==-1) {
     this.scrollList(activeSuggestion-1);
     }

      if(activeSuggestion === -1) {
        downlist.scrollTop = downlist.scrollHeight;
      this.setState({
        activeSuggestion: clientfiltSuggestions.length-1
      });
      return;
    }

      if (activeSuggestion === 0) {
        this.setState({
          activeSuggestion: -1,
          userClientInput: this.state.usrClientInput
        });
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
  else if (e.keyCode === 40 && e.target.value.trim()!=='') {
   if(this.state.clientListshowSuggestions && activeSuggestion!==-1) {
      this.scrollList(activeSuggestion+1);
   }

     if (activeSuggestion - 1 === clientfiltSuggestions.length-2) {
       downlist.scrollTop=0;
       this.setState({
         activeSuggestion: -1,
         userClientInput: this.state.usrClientInput
         });
         return;
     }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  }

  clearInput = evt => {
    const {onSelectChange, clientCoName, toInd, money, coTxt} = this.props;
      const clearObj = money ? {toInd} : {};
      this.setState({userClientInput: '',
      clientListshowSuggestions: true, clientfiltSuggestions: ['Add New', ...clientCoName]
      }, ()=> {
        onSelectChange(coTxt ? coTxt : 'coName', '', clearObj);
      });
  }

  showSuggestion = (evt, inp) => {
    const {clientCoName} = this.props;
    const onUnClick = document.getElementById(inp);
      if(onUnClick) {
        onUnClick.focus();
      }
      this.setState({userClientInput: '', clientListshowSuggestions: true, clientfiltSuggestions: ['Add New', ...clientCoName]});
  }

  onBlurDropDown = () => {
    this.setState({clientListshowSuggestions: false, clientfiltSuggestions:[]});
  }

  onFocusDropDown = () => {
    const {clientCoName} = this.props;
    this.setState({clientListshowSuggestions: true, clientfiltSuggestions: ['Add New', ...clientCoName]});
  }

  openClientDialog = (evt, coId) => {
    const {openCliDialog} = this.props;
    this.setState({activeSuggestion: 0, userClientInput: '', clientListshowSuggestions: false, clientfiltSuggestions:[]}, ()=> {
      let tmpCoArg = coId.trim() && coId.indexOf('-')!==-1 ? coId.split('-')[0].trim() : coId;
      openCliDialog(tmpCoArg, true);
    });
  }

  render() {
    const {
      onChange,
      onKeyDown,
      state: {
        activeSuggestion,
        clientfiltSuggestions,
        clientListshowSuggestions,
        userClientInput
      }
    } = this;
    let suggestionsListComponent;
    const {money, placeHoldCo, toInd, topClass, inputAuoStyle, autoCompleteClass} = this.props;
    const indTxt = money && typeof toInd === 'number' ? toInd: '';
    const overRideStyle = topClass ? {...topClass} : {};
    const {labelStyle, inputStyle} = autoCompleteClass ? autoCompleteClass : {};
    if (clientListshowSuggestions) {
      if (clientfiltSuggestions.length) {
        suggestionsListComponent = (
          //START: Bug 5010 Search Customer Bug : use reference of mouse event on parent div...
          <ul className={money ? 'moneysuggest' : 'suggestions'} style={{...overRideStyle}} ref={this.dropDownRef}>
            {clientfiltSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = money ? "moneysuggest-active" : "suggestion-active";
              }
              return (
                <>
                  {suggestion === 'Add New' && 
                  <li key={suggestion} className={className} onClick={evt=>this.openClientDialog(evt, userClientInput)} role="option">
                    <span style={{fontSize:'27px', color:'#006400'}}>+</span>&nbsp;&nbsp;
                    <span style={{color:'#000000'}}>Add New <span style={{fontSize:'18px'}}>{userClientInput}</span></span>
                  </li>
                  }
                  {suggestion !== 'Add New' && <li className={className} key={suggestion} onClick={e => this.onClick(e, suggestion)} role="option">
                    {suggestion}
                  </li>
                  }
                </>
              );
            })}
          </ul>
        );
      }
    }
    return (
      <>
        <TextField
              label={money && placeHoldCo ? <span style={labelStyle ? {...labelStyle}:{position:'relative', bottom:'2px'}}>{placeHoldCo}</span> : <span style={labelStyle ? {...labelStyle}:{position:'relative', bottom:'2px'}}>{money ? 'Customer *...' : `${userClientInput ? 'Customer' : 'Search Customer'} ${inputAuoStyle ? '': '*'}...`}</span>}
              type="text" variant="outlined"
              fullWidth value={userClientInput}
              inputProps={{
                  'aria-label': ''
              }}
              InputProps={{
                style:inputStyle ? {...inputStyle}:{},
                endAdornment: (
                  <InputAdornment position="end">
                    {userClientInput && (
                      <Icon aria-hidden="true" style={{cursor: 'pointer',fontSize:'20px'}} onClick={evt =>this.clearInput(evt)}>clear</Icon>
                    )}
                    <Icon aria-hidden="true" style={{cursor: 'pointer',fontSize:'20px'}} onClick={evt =>this.showSuggestion(evt, "ErrorDropDown")}>arrow_drop_down</Icon>
                  </InputAdornment>
                )
              }}
              onChange={onChange} autoComplete="off"
              onKeyDown={onKeyDown} id={money ? `MoneyDropDown${indTxt}` : `ErrorDropDown${toInd ? toInd: ''}`}
            //   onBlur={this.onBlurDropDown.bind(this)}
              onFocus={this.onFocusDropDown.bind(this)}
        />
        {suggestionsListComponent}
      </>
    );
  }
}

export default Autocomplete; 