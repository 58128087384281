import React from 'react';
import { 
    DialogContentText, Grid, Icon, Button, Dialog, DialogTitle, DialogActions, DialogContent,
    AppBar, Toolbar, IconButton, TextField, MenuItem
}  from '@material-ui/core';
import axios from 'axios';
import {platformHrThemeColor} from '../../../../genericFunctions';
import Apiconfig from "configurations";
const {allowCoSpecialChars} = Apiconfig;


class ClientDialog extends React.Component {

  state = {
    delClientBox: false
  }

  closeDelDialog = (del=false) => {
    const {handleClose, reloadClients} = this.props;
    this.setState({delClientBox: false}, async()=>{
      if(del) {
        handleClose();
        await reloadClients();
      }
    });
  }

  deleteClientFromList = async () => {
    const {tmpCoId} = this.props;
    try {
          await axios.put(`/api/bankRec/items/removeClient/${tmpCoId}`);
          this.closeDelDialog(true);
    }
    catch(errCli) {
      console.error(errCli);
      this.closeDelDialog();
    }
  }

    delClientContent = () => {
      const {delClientBox} = this.state;
      const {tmpCoId} = this.props;
      return (
        <Dialog
            open={delClientBox}
            onClose={()=>this.closeDelDialog(false)}
            aria-labelledby="alert-dialog-clidel" aria-describedby="alert-dialog-clideldescription"
        >
            <DialogTitle id="alert-dialog-clidel">{"Are you sure ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-clideldescription">
                    {`Are you sure you want to permanently delete customer ${tmpCoId} ? This action cannot be undone.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>this.closeDelDialog(false)} variant="contained" color="primary" autoFocus>
                   Cancel
                </Button>
                <Button onClick={this.deleteClientFromList} variant="contained" color="secondary">
                    Yes
                  </Button>
            </DialogActions>
        </Dialog>
      )
    }

    openDelDialog = () => {
      this.setState({delClientBox: true});
    }

    render() {
    const {
        openDialogClients, handleClose, handleChange, saveClientData, compId, compName, compFein,
        compStatus, enableSave, chkAddClient, dupFlag, isCliDel
    } = this.props;
    const re = new RegExp(window.atob(allowCoSpecialChars));
    let finalCheck = false;
    if(chkAddClient === 'add' && enableSave && re.test(compId.trim())) {
      finalCheck = true;
    } else {
      if(chkAddClient === 'edit') {
        finalCheck = true;
      }
    }
        return(
          <>
            <Dialog
            open={openDialogClients}
            onClose={handleClose}
            disableBackdropClick
            disableEscapeKeyDown
            >
        <DialogTitle component="div" className="p-0">
        <AppBar style={{ color: 'white', backgroundColor:  platformHrThemeColor }} position="static" elevation={1}>
                    <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16" style={{ overflowX: "scroll", overflowY: "hidden" }}>
                    <div className="flex flex-1">
                    {chkAddClient === 'add' ? 'Add New Customer' : 'Edit Customer'}
                    </div>
                    {chkAddClient === 'edit' && typeof isCliDel === 'boolean' && isCliDel && 
                      <Icon style={{position:'relative', right:'15px', cursor:'pointer'}} onClick={()=>this.openDelDialog()}>delete</Icon>
                    }
                    <IconButton color="inherit" >
                            <Icon onClick={handleClose}>close</Icon>
                        </IconButton>
                    </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className="p-16 sm:p-24">
          <div className="mb-16 sm:mb-8 flex">
          <Grid container spacing={3}>
          {dupFlag && <Grid item xs={12} style={{color:'red', textAlign:'center', marginBottom:'14px'}}>coId and coFein is already present in client. Please choose different!</Grid>}
              {
              <>
              { chkAddClient === 'add' &&
              <Grid item xs={12} sm={6} style={{paddingBottom:'5px'}}>
                    <TextField
                      margin="dense"
                      id="coId"
                      label={<span style={{position:'relative', bottom: compId ? '0px' : '2px'}}>Customer</span>}
                      type="text"
                      variant="outlined"
                      onChange={evt => handleChange(evt, 'compId')}
                      value={compId} fullWidth style={{width:'91%'}}
                      inputProps={{ maxLength: 10 }}
                      helperText={compId.trim() && !re.test(compId.trim()) ? 'Only _&- and space is allowed in special characters' : ''}
                  />
              </Grid>
             }
              <Grid item xs={12} sm={6} style={chkAddClient === 'edit' ? {paddingBottom:'5px'}:{paddingLeft:'28px'}}>
              <TextField
                    margin="dense"
                    id="coName"
                    label={<span style={{position:'relative', bottom: compName ? '0px' : '2px'}}>Customer Name</span>}
                    type="text" style={{width:'100%'}}
                    variant="outlined"
                    onChange={evt => handleChange(evt, 'compName')}
                    value={compName} fullWidth
                    />
              </Grid>
              <Grid item xs={12} sm={6} style={chkAddClient === 'edit' ? {paddingLeft:'28px'}:{}}>
              <TextField
                  margin="dense"
                  id="coFein"
                  label={<span style={{position:'relative', bottom: compFein ? '0px' : '2px'}}>FEIN</span>}  style={{width: chkAddClient === 'edit' ? '100%' : '91%'}}
                  type="text"
                  variant="outlined"
                  onChange={evt => handleChange(evt, 'compFein')}
                  value={compFein} fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} style={chkAddClient === 'add' ? {paddingLeft:'28px', paddingTop:'8px'} : {position:'relative', top:'7px'}}>
                <TextField
                        value={compStatus}
                        onChange={evt => handleChange(evt,'compStatus')}
                        fullWidth variant="outlined" select label={<span style={{position:'relative', bottom: compStatus ? '0px' : chkAddClient === 'add' ? '7px' : '6px'}}>Status</span>} 
                        InputProps={{style:{height:'49px'}}} style={{width:'100%'}}
                >
                        <MenuItem value="">Select Status</MenuItem>
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Inactive"}>Inactive</MenuItem>
                        <MenuItem value={"Terminated"}>Terminated</MenuItem>
                </TextField>
              </Grid>
              </> 
              }
          </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus 
            onClick={handleClose} 
            style={{color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize'}}
            >
            Cancel
          </Button>
          <Button 
            onClick={saveClientData.bind(this, chkAddClient === 'add' ? 'add' : 'edit')} 
            style={finalCheck ? 
            {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize'}:
            {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', pointerEvents:'none', opacity:0.5}}
            >
            {chkAddClient === 'add' ? 'Save' : 'Update'}
          </Button>
        </DialogActions>
    </Dialog>
    {this.delClientContent()}
    </>
        );
    }

}

export default ClientDialog;

