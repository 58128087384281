import React, { Component } from 'react'
import { withStyles, Card, CardContent, Typography, InputAdornment, Icon, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse';
import classNames from 'classnames';
import _ from '@lodash';
const imgUrl = "assets/images/backgrounds/office-chair.png";


/**
 * This method handles the background image for the login screen
 * @param {*} theme - Not in use for this version.
 * @returns {object} - The CSS needed for adding background image.
 */
const styles = theme => ({
    root: {
        backgroundImage: 'url(' + imgUrl + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
});

class ForgotPassword extends Component {
    state = {
        canSubmit: false
    };

    form = React.createRef();

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onSubmit = (model) => {
        //alert('Email Sent', { model });
        console.log(`Email sent ${model}`);
        //  this.props.submitLogin(model);
    };

    //handle method for remember me check.
    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name));
    };


    render() {
        const { classes } = this.props;
        const cardContentClass = "flex flex-col flex-no-grow items-center text-white p-16 text-center md:p-128 md:items-start md:flex-no-shrink md:flex-1 md:text-left";
        return (
            <div className={"flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0"}>
                <div className={classNames(classes.root, cardContentClass)}></div>
                <Card className=" max-w-400 mx-auto m-16 md:m-0" square>
                    <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-108">
                        <img width="220" src="assets/images/logos/fuse.svg" alt="logo" />
                        <Typography variant="subtitle1" className="text-center text md:w-full mb-4">RECOVER YOUR PASSWORD</Typography>
                        <br />
                        <div className="w-full mt-4">

                            <Formsy
                                onValidSubmit={this.onSubmit}
                                onValid={this.enableButton}
                                onInvalid={this.disableButton}
                                ref={(form) => this.form = form}
                                className="flex flex-col justify-center w-full"
                            >
                                <TextFieldFormsy
                                    className="mb-16"
                                    type="email"
                                    name="email"
                                    label="Email"
                                    validations={{
                                        minLength: 4
                                    }}
                                    validationErrors={{
                                        minLength: 'Min character length is 4'
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">email</Icon></InputAdornment>
                                    }}
                                    variant="outlined"
                                    required
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="w-full mx-auto mt-16 normal-case"
                                    aria-label="LOG IN"
                                    disabled={false}
                                    value="legacy"
                                style={{ backgroundColor: '#039be5' }}
                                >
                                    SEND RESET LINK
    </Button>

                            </Formsy>
                        </div>
                    </CardContent>
                </Card>

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(ForgotPassword));
