//import { setUserDataAuth0 } from "./user.actions";
//import _ from "@lodash";
import auth0Service from "services/auth0Service";
//import history
import history from "../../../history";
//import setuserData for user action
import { setUserData } from "auth/store/actions/user.actions";
//export loginerror
export const LOGIN_ERROR = "LOGIN_ERROR";
//export login success
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
//export MFA_ERROR
export const MFA_ERROR = "MFA_ERROR";
//export MFA_SUCCESS
export const MFA_SUCCESS = "MFA_SUCCESS";
//define function submitLogin
export const submitLogin = ({ email, password }) => {
  /**
   * After successful login, dispatch the data to reducer.
   */
  return dispatch =>
    auth0Service
      .signInWithEmailAndPassword(email, password, dispatch)
      .then(user => {
        //debugger
        // after successful signin, navigate to dashboard
        // var role = _.get(user, "https://visivo:auth0:com/app_metadata.authorization.roles[0]", 'simple_user');
        // localStorage.setItem('role', role);
        // if (role === 'simple_user') { //Asper role, set the initial dashboard
        // history.push('/apps/dashboards/customers');
        // } else {
        // history.push('/apps/Home');
        // }
        if (user.data.success && user.data.mfa_token) {
          //set localStorage for mfaa_token
          localStorage.setItem("mfa_token", user.data.mfa_token);
          //set localStorage for email
          localStorage.setItem('email', email);
          //window.location.pathname = "/login/mfa";
          //redirect to mfa login page
          history.push({
            pathname: '/login/mfa',
            state: {
                //set enrolled authenticator
                enrolled_authenticator : user.data.enroled_auth,
                //set email
                email
            }
        });
          // dispatch(setUserData(user));
          // return dispatch({
          //     type: LOGIN_SUCCESS
          // });
        } else if (user.data.success && user.data.access_token) {
          //redirect to dashboard
          history.push("/app/rPRec/rPRecD");
          dispatch(setUserData(user));
          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          //redirect to login page
          history.push("/login");
          if (user.data.error === "Email is invalid" || user.data.error !== "Email is invalid")
            return dispatch({type: LOGIN_ERROR,payload: { errorMsg: "Invalid email or password"}});
        }
      })
      .catch(error => {
        //check if email is invalide
        if (error === "Email is invalid") {
          //return login error
          return dispatch({
            type: LOGIN_ERROR,
            //pass payload with email message
            payload: { errorMsg: "Invalid email or password"}
          });
        } else if (error === "User Exceed Attempt") {
          return dispatch({
            type: LOGIN_ERROR,
            payload: { errorMsg: "Your account has been locked. Please contact your Administrator for assistance."}
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            //pass payload with password message
            payload: { errorMsg: "Invalid email or password"}
          });
        }
      });
}
//define submitMfalogin function
export const submitMfaLogin =  function(factorParams) {
  /**
   * After successful login, dispatch the data to reducer.
   */
  return dispatch =>
    auth0Service
      .signInWithMfa(factorParams, localStorage.getItem("mfa_token"), dispatch)
      .then(user => {
        if (user.data.success) {
          //if success then show dashboard
          history.push("/app/rPRec/rPRecD");
          //set user defaultsettings
          dispatch(setUserData(user));
          return dispatch({
            type: MFA_SUCCESS
          });
        } else {
          history.push("/login");
          if (user.data.error === "Email is invalid") {
            return dispatch({
              type: MFA_ERROR,
              payload: { email: "Email Not Found. Check your email" }
            });
          } else {
            return dispatch({
              type: MFA_ERROR,
              payload: { password: "Check your password" }
            });
          }
        }
      })
      .catch(error => {
        //go in catch block if promise rejected
        if (error === "Email is invalid") {
          return dispatch({
            type: LOGIN_ERROR,
            payload: { email: "Email Not Found. Check your email" }
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: { password: "Check your password" }
          });
        }
      });
}
//define submitMfafactor function
export const submitMfaFactor = function(mfa_option) {
  /**
   * After successful login, dispatch the data to reducer.
   */
  return dispatch =>
    auth0Service
      .signInWithMfaFactor(mfa_option, localStorage.getItem("mfa_token"), dispatch)
      .then(user => {
          if (user.data && user.data.mfa_factor) {
            //redirect to login mfa by passing email and user data as a parameter
            history.push({
              pathname: '/login/mfa',
              state: {
                  //pass data of user
                  data : user.data,
                  //pass email data alongwith user
                  email: localStorage.getItem('email')
              }
            });
            //return promise when success
            return new Promise((resolve, reject)=>{
              //resolve the data in promise
              resolve(user.data);
            })
          }
          /* history.push("/app/rPRec/rPRecD");
          dispatch(setUserData(user));
          return dispatch({
            type: MFA_SUCCESS
          }); */
         else {
          history.push("/login");
          if (user.data.error === "Email is invalid") {
            return dispatch({
              type: MFA_ERROR,
              payload: { email: "Email Not Found. Check your email" }
            });
          } else {
            return dispatch({
              type: MFA_ERROR,
              payload: { password: "Check your password" }
            });
          }
        }
      })
      .catch(error => {
        if (error === "Email is invalid") {
          return dispatch({
            type: LOGIN_ERROR,
            payload: { email: "Email Not Found. Check your email" }
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: { password: "Check your password" }
          });
        }
      });
}
