import io from "socket.io-client";
const max_socket_reconnects = 6;

const Apiconfig = {
  baseUrl: process.env.REACT_APP_API_URL,
  redirectlogin: process.env.REACT_APP_REDIRECT_URL,
  cryptoKey: process.env.REACT_APP_CRYPTO_KEY,
  accountKey: process.env.REACT_APP_ACCOUNT_KEY,
  bankRecHelp: process.env.REACT_APP_BANKREC_HELP_URL,
  clientFundsHelp: process.env.REACT_APP_CLIENTFUNDS_HELP_URL,
  emailSupport: process.env.REACT_APP_EMAIL_SUPPORT_URL,
  recApi: process.env.REACT_APP_RECAPI_URL,
  allowCoSpecialChars: process.env.REACT_APP_ALLOW_SPECIALCHARS_COID,
  taxCodeExistHelp: process.env.REACT_APP_TAXCODE_EXIST_HELP_URL,
};

export const socket = io.connect(process.env.REACT_APP_API_URL,{
  rememberTransport: false,
  transports: ['websocket', "polling"],
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax : 1000,
  reconnectionAttempts: Infinity
 });
 
socket.on("reconnecting", function(delay, attempt) {
  if (attempt === max_socket_reconnects) {
    setTimeout(function(){ socket.socket.connect(); }, 5000);
    return console.log("Failed to reconnect. Lets try that again in 5 seconds.");
  }
});

socket.on('error', function(){
  console.log(`error in socket connecting back`);
});

socket.on('reconnect', () => {
  console.log('Socket reconnected');
});

socket.on("disconnect", reason => {
  console.log("disconnect due to " + reason);
  if(!socket.connected) {
    /** START: Bug 5152: Data crossing tenants */
    const sessSockInfo = sessionStorage.getItem("sessionInfo")
    let sessionOrgId = typeof sessSockInfo === 'string' && sessSockInfo.trim() ? parseInt(sessSockInfo.trim()) : null;
    socket.emit("MessageFromPlatformHR", {
      token: localStorage.getItem("token"),
      sessionOrgId
    });
    /** END: Bug 5152: Data crossing tenants */
  }
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
  socket.io.opts.transports = ["polling", "websocket"];
});

export default Apiconfig;
