const fuseDark = {
    50  : '#fdfdfd',
    100 : '#fdfdfd',
    200 : '#fdfdfd',
    300 : '#fdfdfd',
    400 : '#fdfdfd',
    500 : '#fdfdfd',
    600 : '#fdfdfd',
    700 : '#fdfdfd',
    800 : '#fdfdfd',
    900 : '#fdfdfd',
    A100: '#fdfdfd',
    A200: '#fdfdfd',
    A400: '#fdfdfd',
    A700: '#fdfdfd'
};

export default fuseDark;