import * as Actions from '../actions';

const initialState = {
    role: 'guest',
    data: {
        'displayName': 'John Doe',
        'photoURL': 'assets/images/avatars/Velazquez.jpg',
        'email': 'johndoe@withinpixels.com',
        shortcuts: [
            'calendar',
            'mail',
            'contacts',
            'todo'
        ]
    },
    organizations: null,
    organizations_update: null,
    disorgId: null
};

const user = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_USER_DATA:
            {
                return {
                    ...initialState,
                    ...action.payload
                };
            }
        case Actions.REMOVE_USER_DATA:
            {
                return {
                    ...initialState
                };
            }
        case Actions.USER_LOGGED_OUT:
            {
                return initialState;
            }
        case Actions.ORGANIZATIONS:
            {
                return {
                    ...state,
                    organizations: action.payload
                };
            }
        case Actions.ORGANIZATIONS_UPDATE:
            {
                return {
                    ...state
                };
            }
        case Actions.ORGANIZATION_ID:
            {
                return {
                    ...state,
                    disorgId: action.payload
                }
            }
        default:
            {
                return state
            }
    }
};

export default user;

