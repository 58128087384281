import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {Icon} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
//import CloseIcon from '@material-ui/icons/Close';
//import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const styles = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
  
//   const DialogTitle = withStyles(styles)(props => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//       <MuiDialogTitle disableTypography className={classes.root} {...other}>
//         <Typography variant="h6">{children}</Typography>
//         {onClose ? (
//           <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//             <CloseIcon />
//           </IconButton>
//         ) : null}
//       </MuiDialogTitle>
//     );
//   });
  
//   const DialogContent = withStyles(theme => ({
//     root: {
//       padding: theme.spacing(2),
//     },
//   }))(MuiDialogContent);
  
//   const DialogActions = withStyles(theme => ({
//     root: {
//       margin: 0,
//       padding: theme.spacing(1),
//     },
//   }))(MuiDialogActions);


class UpdateUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        open: false,
        email: null,
        errortext: false,
        errortextfirstname: false,
        errortextnumber: false,
        erroremail: false,
        password: null,
        phoneno: null,
        passwordcheck: null,
        firstname: null,
        lastname: null,
        editUser: null, passwordErrProfMsg: ''
        }
    }

    async componentDidMount() {
        const {open, id} = this.props;
        if (open) {
            try {
                const response = await axios.get(`/api/users/userinfo/${id}`);
                const res = response.data;
                if (res && res.status === 200) {
                    const obj = res.data[0];
                    this.setState({
                        open,
                        email: obj.email,
                        firstname: obj.firstName,
                        lastname: obj.lastName,
                        phoneno: obj.phoneNumber,
                        editUser: true,
                        errortext: false,
                        errortextfirstname: false,
                        errortextnumber: false,
                        errorpassword: false

                    });
                }
            }
            catch(err) {
                console.log(err);
            }
        }
    }

    emailset = event => {
        this.setState({ email: event.target.value });

        if (event.target.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || event.target.value === '') {
            this.setState({
                erroremail: false,
            });

        } else {

            this.setState({
                erroremail: true,
            });

        }
    };

    passwordset = event => {
        const val = event.target.value;
        let strongPasswordProfile = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
        if(strongPasswordProfile.test(val)) {
            this.setState({passwordErrProfMsg: '', password: val});
        } else {
            this.setState({passwordErrProfMsg: 'Passwords must include at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.', password: val});
        }
    };
    passwordcheckset = event => {
        this.setState({ passwordcheck: event.target.value })
    };

    handleClose = () => {
        const {close} = this.props;
        this.setState({open: false}, ()=>{
            close();
        })
    };

    firstnameset = event => {
        this.setState({ firstname: event.target.value })
        if (event.target.value.match(/^[a-zA-Z\s]+$/) || event.target.value === '') {
            this.setState({

                serviceHTTPSCaError: "",
                errortextfirstname: false,
            });

        } else {

            this.setState({
                serviceHTTPSCaError: "Invalid format!",
                errortextfirstname: true,
            });
        }
    };

    lastnameset = event => {
        this.setState({ lastname: event.target.value });
        if (event.target.value.match(/^[a-zA-Z\s]+$/) || event.target.value === '') {
            this.setState({

                serviceHTTPSCaError: "",
                errortext: false,
            });

        } else {

            this.setState({
                serviceHTTPSCaError: "Invalid format!",
                errortext: true,
            });
        }
    };

    phonenumberset = event => {
        this.setState({ phoneno: event.target.value });
        if (event.target.value.match(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/) || event.target.value === '') {
            this.setState({


                errortextnumber: false,
            });

        } else {

            this.setState({
                serviceHTTPSCaError: "Invalid format!",
                errortextnumber: true,
            });
        }


    };

    errorpasswordset = () => {
        if (this.state.password === this.state.passwordcheck) {
            this.setState({ errorpassword: false });
        }
        else if (this.state.passwordcheck === '') {

            this.setState({ errorpassword: false });
        }
        else {
            this.setState({ errorpassword: true })
        }
    }

    updateUserInfo = async () => {
        const {id, close} = this.props;
        const {phoneno, firstname, lastname, errortextfirstname, errortext, errortextnumber, errorpassword, password, email} = this.state;
        if (errortextfirstname === false && errortext === false && errortextnumber === false && errorpassword === false) {
            try {
                        await axios.put(`/api/users/updateUserInfo/${id}`, {
                        firstName: firstname,
                        lastName: lastname,
                        phoneNumber: phoneno,
                        password,
                        email
                    });
                    this.setState({open: false}, ()=>{
                        close();
                    });
            }
            catch(err) {
                console.log(err);
            }
        }
    }

    render() {
        const {open, passwordErrProfMsg, passwordcheck, password} = this.state;
        return(
        <>
        <Dialog 
            onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}
            disableBackdropClick disableEscapeKeyDown
        >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Update User
          <IconButton color="inherit" style={{float:'right', position:'relative', bottom:'8px'}}>
            <Icon onClick={this.handleClose}>close</Icon>
         </IconButton>
        </DialogTitle>
        <DialogContent dividers >
        <TextField
                        margin="dense"
                        variant="outlined"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        disabled={this.state.editUser}
                        onChange={this.emailset}
                        value={this.state.email}
                        error={this.state.erroremail}
                    />

                    <div className="flex w-full">
                        <div className="flex w-1/2 items-left" style={{ 'flex': '0 0 48%' }}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                onChange={this.passwordset}
                                helperText={passwordErrProfMsg}
                                error={passwordErrProfMsg ? true: false}
                            />
                        </div>
                        <div className="flex  w-1/2 items-left" style={{ 'flex': '0 0 48%', marginLeft: '25px' }}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                id="rewritePassword"
                                label="Rewrite Password"
                                type="password"
                                //fullWidth
                                style={{width:'97%'}}
                                onChange={this.passwordcheckset}
                                // helperText="password must be same as above"
                                // helperText={this.helpertext}
                                helperText={(password && password === passwordcheck) ? <p>Password Match</p> : <p>Password must be same</p>}
                                onKeyUp={this.errorpasswordset}
                                error={this.state.errorpassword}
                                disabled={passwordErrProfMsg ? true: false}
                            />
                        </div>
                    </div>


                    <div className="flex w-full">
                        <div className="flex w-1/2 items-left" style={{ 'flex': '0 0 48%' }}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                id="firstName"
                                label=" First Name"
                                value={this.state.firstname}
                                type="text"
                                fullWidth
                                onChange={this.firstnameset}
                                error={this.state.errortextfirstname}
                            />
                        </div>
                        <div className="flex  w-1/2 items-left" style={{ 'flex': '0 0 48%', marginLeft: '25px' }}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                id="lastName"
                                label=" Last Name"
                                value={this.state.lastname}
                                type="text"
                                //fullWidth
                                style={{width:'97%'}}
                                onChange={this.lastnameset}
                                error={this.state.errortext}
                            />
                        </div>
                    </div>


                    <TextField
                        margin="dense"
                        variant="outlined"
                        id="phoneNumber"
                        label=" Phone Number"
                        value={this.state.phoneno}
                        placeholder="122-123-1234"
                        helperText={'phone number format 111-111-1111'}
                        fullWidth
                        onChange={this.phonenumberset}
                        error={this.state.errortextnumber}
                    />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.updateUserInfo} color="success" style={(password && password !== passwordcheck) ? {pointerEvents: 'none', opacity: 0.5, backgroundColor: '#D3D3D3' }:{backgroundColor: '#D3D3D3'}}>
            Save
          </Button>
        </DialogActions>
      </Dialog>        
    </>
        );
    }
}

export default withStyles(styles, {withTheme: true})(UpdateUserProfile);