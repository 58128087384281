//import React
import React from 'react';
//import icon
import { Icon, ListItem, ListItemText } 
from '@material-ui/core';
//import withstyles
import { withStyles } from '@material-ui/core/styles/index';
//import Navlink
import { NavLink, withRouter } from 'react-router-dom';
//import classnames
import classNames from 'classnames';
//import FuseNavBadge
import FuseNavBadge from './../FuseNavBadge';
//import Proptypes
import PropTypes from 'prop-types';
//import connect
import { connect } from 'react-redux';
//import bindActionCreators
import { bindActionCreators } from 'redux';
//import Action as *
import * as Actions from 'store/actions';

//set propTypes
const propTypes = {
    item: PropTypes.shape(
        {
            //set id as required
            id: PropTypes.string.isRequired,
            //set title of type string
            title: PropTypes.string,
            //set icon as string
            icon: PropTypes.string,
            //set url as string
            url: PropTypes.string
        })
};
//set defaultProps as empty object
const defaultProps = {};
//set styles
const styles = theme => ({
    item: {
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingRight: 12,
        '&.active': {
            //backgroundColor: theme.palette.secondary.main,
            backgroundColor: 'rgb(45, 184, 194)',
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary': {
                color: 'inherit'
            },
            '& .list-item-icon': {
                color: 'inherit'
            }
        },
        '&.square, &.active.square': {
            width: '100%',
            borderRadius: '0'
        },
        '& .list-item-icon': {},
        '& .list-item-text': {},
        color: 'inherit!important',
        textDecoration: 'none!important'
    }
});
//define function FuseNavVerticalItem
const FuseNavVerticalItem = ({ item, classes, nestedLevel, userRole, navbarCloseMobile, active, onChildItem, isFileImport }) => {
    if (item.auth && (!item.auth.includes(userRole) || (userRole !== 'guest' && item.auth.length === 1 && item.auth.includes('guest')))) {
        return null;
    }
    let paddingValue = 40 + (nestedLevel * 16);
    const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24';
    //let item_vertical = item;
    // debugger;
    /**
     * @method this method will the selected child item
     */
    const onClickValue = () => {
        onChildItem();
    }
    //return ListItem of menu
    return (
        <>
            { isFileImport ?
                <ListItem
                    button
                    //component={NavLink}
                    // to={item.url}
                    //to={item.childMenu.url}
                    activeClassName="active"
                    className={classNames(classes.item, listItemPadding, 'list-item', active)}
                    exact={item.exact}
                    disabled={item.childMenu.disable ? item.childMenu.disable: false}
                >
                    {item.icon && (
                        <Icon className="list-item-icon text-16 flex-no-shrink" color="action">{item.icon}</Icon>
                    )}
                    {/* <ListItemText className="list-item-text" primary={item.title} classes={{primary: 'text-14 list-item-text-primary'}}/> */}
                    <ListItemText className="list-item-text" primary={item.childMenu.displayName} classes={{ primary: 'text-14 list-item-text-primary' }} />
                    {item.badge && (
                        <FuseNavBadge badge={item.badge} />
                    )}
                </ListItem>
                :
                <ListItem
                    button
                    component={NavLink}
                    // to={item.url}
                    to={item.childMenu.url}
                    activeClassName="active"
                    className={classNames(classes.item, listItemPadding, 'list-item', active)}
                    onClick={onClickValue}
                    exact={item.exact}
                    disabled={item.childMenu.disable ? item.childMenu.disable: false}
                >
                    {item.icon && (
                        <Icon className="list-item-icon text-16 flex-no-shrink" color="action">{item.icon}</Icon>
                    )}
                    {/* <ListItemText className="list-item-text" primary={item.title} classes={{primary: 'text-14 list-item-text-primary'}}/> */}
                    <ListItemText className="list-item-text" primary={item.childMenu.displayName} classes={{ primary: 'text-14 list-item-text-primary' }} />
                    {item.badge && (
                        <FuseNavBadge badge={item.badge} />
                    )}
                </ListItem>
            }
        </>
    );
}
//define function mapDispatchToProps
let mapDispatchToProps = dispatch => {
    return bindActionCreators({
        navbarCloseMobile: Actions.navbarCloseMobile,
    }, dispatch);
}
//define function mapStateToProps
let mapStateToProps = ({ auth, fuse }) => {
    //return User Role
    return {
        userRole: auth.user.role,
    }
}
//set proptypes
FuseNavVerticalItem.propTypes = propTypes;
//set defaultProps
FuseNavVerticalItem.defaultProps = defaultProps;
//connect with redux mapStateToProps
const NavVerticalItem = withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseNavVerticalItem)));
//export default as component
export default NavVerticalItem;
