import { AnalyticsDashboardAppConfig } from "./dashboards/analytics/AnalyticsDashboardAppConfig";
import { MailAppConfig } from "./mail/MailAppConfig";
import { TodoAppConfig } from "./todo/TodoAppConfig";
import { ContactsAppConfig } from "./contacts/ContactsAppConfig";
import { FileManagerAppConfig } from "./file-manager/FileManagerAppConfig";
import { CalendarAppConfig } from "./calendar/CalendarAppConfig";
import { ChatAppConfig } from "./chat/ChatAppConfig";
import { ECommerceAppConfig } from "./e-commerce/ECommerceAppConfig";
import { ScrumboardAppConfig } from "./scrumboard/ScrumboardAppConfig";
import { InfrastructureConfig } from "./readypayonline/rpoinfrastructure/InfrastructureConfig";
import { HealthstatusConfig } from "./readypayonline/rpohealthstatus/HealthstatusConfig";
import { OperationsConfig } from "./readypayonline/rpooperations/OperationsConfig";
import { RpHealthstatusConfig } from "./readypayiaas/rphealthstatus/RpHealthstatusConfig";
import { RpInfrastructureConfig } from "./readypayiaas/rpinfrastructure/RpInfrastructureConfig";
import { RpOperationsConfig } from "./readypayiaas/rpoperations/RpOperationsConfig";
import { M3HealthstatusConfig } from "./millenniumiaas/m3healthstatus/M3HealthstatusConfig";
import { M3OperationsConfig } from "./millenniumiaas/m3operations/M3OperationsConfig";
import { M3InfrastructureConfig } from "./millenniumiaas/m3infrastructure/M3InfrastructureConfig";
import { RPImplementationDashboardConfig } from "./RPImplementation/RPImplementationDashboard/RPImplementationDashboardConfig";
import { RPReconcilliationDashboardConfig } from "./RPReconciliation/RPReconciliationDashboard/RPReconciliationDashboardConfig";
import { RPReconcilliationWorkflowConfig } from "./RPReconciliation/RPReconciliationWorkflow/RPReconciliationWorkflowConfig";
import { QueryToolConfig } from "./RPReconciliation/QueryTool/QueryToolConfig";
import { RPReconcilliationWorkflowReadOnlyUsersConfig } from "./RPReconciliation/RPReconciliationWorkflowReadOnlyUsers/RPReconciliationWorkflowConfig";
import { User1Config } from "./Users/User1/User1Config";
import { organisationsConfig } from "./organisations/organisations/organisationsConfig";
import { AgentAdminConfig } from "./agentAdmin/AgentAdmin";
import {ClientViewConfig} from "./clientView/ClientView";
import {OrganizationReportConfig} from "./organizationReport/index";
import {ClientListConfig} from './clientList/index';
import {ReportsConfig} from './reports/index';
import {ImplementationConfig} from './payroll/implementation/index';
import {ImplAdminConfig} from './payroll/implAdmin/index';
import {ImportConfig} from './ClientFundsManagement/Import/index';
import {ManageConfig} from './ClientFundsManagement/Manage/index';
import {ManageDashboardConfig} from './ClientFundsManagement/DashBoard/index';
import {WorkflowConfig} from './bankRec/workflow/index';
import {BankRecKanbanConfig} from './bankRec/kanban/index';
import {BankRecDashboardConfig} from './bankRec/dashboard/index';
import {BankRecPanelConfig} from './bankRec/bankRecPanel/index';
import {RestartDemoConfig} from './restartDemo/index';
import {ImportBalancesConfig} from './adminScreen/index';
import {NewLedgerConfig} from './newLegerUI/index';
import { OutstandingLiabilitiesConfig } from './outstandingLiabilitiesUI/index'; // #ADD 5041 - Liability Adjustments - Config
import {newReportsConfig} from './newReports/index';
import {EncryptConfig} from './bankEncryption/index';
import {ImportLogConfig} from './bankRec/importLogs/index';
import {RematchTransConfig} from './reMatchTrans/index';
import {ImportErrorMessageConfig} from './importErrorMessage/index';
import {FileImportActivityConfig} from './fileImportActivity/index';
import {ReportsComponentsConfig} from './ReportsComponents/index';

export const appsConfigs = [
  organisationsConfig,
  RPReconcilliationWorkflowConfig,
  RPReconcilliationWorkflowReadOnlyUsersConfig,
  QueryToolConfig,
  User1Config,
  RPReconcilliationDashboardConfig,
  RPImplementationDashboardConfig,
  M3InfrastructureConfig,
  M3OperationsConfig,
  M3HealthstatusConfig,
  RpOperationsConfig,
  RpInfrastructureConfig,
  RpHealthstatusConfig,
  OperationsConfig,
  InfrastructureConfig,
  HealthstatusConfig,
  AnalyticsDashboardAppConfig,
  MailAppConfig,
  TodoAppConfig,
  FileManagerAppConfig,
  ContactsAppConfig,
  CalendarAppConfig,
  ChatAppConfig,
  ECommerceAppConfig,
  ScrumboardAppConfig,
  AgentAdminConfig,
  ClientViewConfig,
  OrganizationReportConfig,
  ClientListConfig,
  ReportsConfig,
  ImplementationConfig,
  ImplAdminConfig,
  ImportConfig,
  ManageConfig,
  ManageDashboardConfig,
  WorkflowConfig,
  BankRecKanbanConfig,
  BankRecDashboardConfig,
  BankRecPanelConfig,
  RestartDemoConfig,
  ImportBalancesConfig,
  NewLedgerConfig,
  OutstandingLiabilitiesConfig, // #ADD 5041 - Liability Adjustments - Config
  newReportsConfig,
  EncryptConfig,
  ImportLogConfig,
  RematchTransConfig,
  ImportErrorMessageConfig,
  FileImportActivityConfig,
  ReportsComponentsConfig
];
