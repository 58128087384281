import {FuseLoadable} from '@fuse';

export const User1Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : "/app/usersPanel/userUserPanelChild",
            component: FuseLoadable({
                loader: () => import('./User1')
             
            })
        },
      
       
      

    ]
};

