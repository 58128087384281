import React from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} 
from '@material-ui/core';

const AlertDialogScreenLeave = ({alertFileDialog, handleIsFileProcessNextScreen, handleIsFileProcess, dontSwitch}) => {
    return(
        <Dialog
            open={alertFileDialog}
            onClose={handleIsFileProcess}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div style={{backgroundColor: '#ffffff'}}>
                <DialogTitle id="alert-dialog-title">
                    <DialogContentText id="alert-dialog-description" style={{color: '#000'}}>
                        <b>Alert</b>
                    </DialogContentText>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{color: '#000'}}>
                        {dontSwitch ?
                        "Files are currently importing. Please wait until the imports have completed to switch tenants.":
                        <>Files are currently importing. If you leave this screen, your files will continue to import, however your warning/error details will be lost. Stay on this screen to see the warning/error details for your file import(s).<br></br><b>Are you sure you want to leave?</b></>
                        }
                    </DialogContentText>
                </DialogContent>
                    <DialogActions> 
                    {!dontSwitch && <Button onClick={handleIsFileProcessNextScreen} variant="contained" color="primary"> Yes </Button>}
                    <Button onClick={handleIsFileProcess} variant="contained" color="primary" autoFocus> {dontSwitch ? 'Ok' : 'Cancel'} </Button> 
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default AlertDialogScreenLeave;