import { authRoles } from 'auth';
//import Login from './Login';
//import Mfa from './MfaUIComponent';
import {FuseLoadable} from '@fuse';

export const MfaConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path     : '/login/mfa',
            component: FuseLoadable({
                loader: () => import('./Mfa')
            })
        }
    ]
};

