import StatusPage from './StatusPage';
import {authRoles} from 'auth';

export const StatusConfig = {
    settings: {
        layout: {
            config: {
                navbar : { display: false},
                toolbar : { display: false },
                footer : { display: false },
                leftSidePanel : { display: false },
                rightSidePanel : { display: false }
            }
        }
    },
    auth    : authRoles.onlyGuest,
    routes  : [
        {
            path     : '/healthcheck',
            component: StatusPage
        }
    ]
};

