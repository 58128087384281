import React, { Component } from 'react';
import FuseNavVerticalGroup from './vertical/FuseNavVerticalGroup';
//import FuseNavVerticalCollapse from './vertical/FuseNavVerticalCollapse';
//import FuseNavVerticalItem from './vertical/FuseNavVerticalItem';
//import FuseNavHorizontalGroup from './horizontal/FuseNavHorizontalGroup';
//import FuseNavHorizontalCollapse from './horizontal/FuseNavHorizontalCollapse';
//import FuseNavHorizontalItem from './horizontal/FuseNavHorizontalItem';
import { Divider, List, Typography, LinearProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
//import PropTypes from 'prop-types';
import axios from 'axios';
import history from "../../../../src/history";
// import { Typography, LinearProgress } from '@material-ui/core';

// const propTypes = {
//     navigation: PropTypes.array.isRequired
// };

/* const defaultProps = {
    layout: "vertical"
}; */

class FuseNavigation extends Component {
    state = {
        nitin: null,
        arraylisting: [],
    }

    setlocal = name => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    shouldComponentUpdate(nextProps, nextState) {

        return true;
    }

    componentDidMount = () => {


        //let outtoken = this.setlocal('token');


        axios.get(`/api/users/me`)
            .then(res => {
                if (res.status === 200) {
                    //const persons = res.data;
                    const fuseNavigationConfig = res.data.data.setting.organizations;
                    this.setState({ nitin: fuseNavigationConfig });

                    if (this.state.nitin !== null) {
                        let seses = this.state.nitin.map(e => e.menu);
                        this.setState({ arraylisting: seses });
                    }
                }
            }).catch(error => {
                localStorage.clear();
                history.push("/login");
            })
    }
    render() {
        const { layout } = this.props;
        //let first = this.state.arraylisting;

        ///method made by nitin
        // if(this.state.nitin!==null){ let seses = this.state.nitin.map(e=>e.menu);
        //     debugger;

        // this.setState({arraylisting:seses})
        // }

        // let finalorganisations = navigation.map(e => e.organizations);
        // let arraylisting = finalorganisations[0].map(r => r.menu);
        // console.log(arraylisting);

        // debugger;

        //methods made by nitin 
        const verticalNav = (
            <List className="whitespace-no-wrap">
                {
                    // navigation.map((item) => (
                    this.state.arraylisting.map(item => (

                        <React.Fragment key={item.id}>

                            {/* {item.type === 'group' && (
                                <FuseNavVerticalGroup item={item} nestedLevel={0} active={active}/>
                            )} */}



                            <FuseNavVerticalGroup item={item} layout={layout} nestedLevel={0} active="rounded/circle" />

                            {/* {item.type === 'collapse' && (
                                <FuseNavVerticalCollapse item={item} nestedLevel={0} active={active}/>
                            )} */}

                            {/* {item.type === 'item' && (
                                <FuseNavVerticalItem item={item} nestedLevel={0} active={active}/>
                            )} */}

                            {item.type === 'divider' && (
                                <Divider className="my-16" />
                            )}
                        </React.Fragment>
                    ))
                }
            </List>
        );

        // const horizontalNav = (
        //     <List className="whitespace-no-wrap flex p-0">
        //         {
        //             navigation.map((item) => (

        //                 <React.Fragment key={item.id}>

        //                     {item.type === 'group' && (
        //                         <FuseNavHorizontalGroup item={item} nestedLevel={0} />
        //                     )}

        //                     {item.type === 'collapse' && (
        //                         <FuseNavHorizontalCollapse item={item} nestedLevel={0} />
        //                     )}

        //                     {item.type === 'item' && (
        //                         <FuseNavHorizontalItem item={item} nestedLevel={0} />
        //                     )}

        //                     {item.type === 'divider' && (
        //                         <Divider className="my-16" />
        //                     )}
        //                 </React.Fragment>
        //             ))
        //         }
        //     </List>
        // );


        if (this.state.nitin && this.state.nitin.length > 0) {

            return verticalNav;
        }
        else {
            return (<div className="flex flex-1 flex-col items-center justify-center">
                <Typography className="text-20 mb-16" color="textSecondary">Loading...</Typography>
                <LinearProgress style={{
                    marginTop: '1px',
                    width: '120px'
                }} color="secondary" />
            </div>
            )
        }
    }
}

// FuseNavigation.propTypes = propTypes;
// FuseNavigation.defaultProps = defaultProps;

export default withRouter(FuseNavigation);
