//import FuseLoadable rematchTrans
import { FuseLoadable } from "@fuse";
//export ImportErrorMessage
export const ImportErrorMessageConfig = {
  //import Error Message settings
  settings: {
    //set import Error Message layout 
    layout: {
      //set import Error Message config as empty object
      config: {}
    }
  },
  //set routes
  routes: [
    {
      path: "/app/bankRec/ErrorMessage/:id",
      component: FuseLoadable({
        loader: () => import("./ImportErrorMessage.js")
      })
    }
  ]
  //end import Error Message routes
};
