import moment from 'moment';
import { 
    Fade, CircularProgress, Snackbar, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog, SnackbarContent,
    IconButton
} from '@material-ui/core';
import React from 'react';
import fileSaver from "file-saver";
import CloseSnkIcon from '@material-ui/icons/Close';

export const ERROR_UNAUTHORISED_MSG = 'Unauthorized to perform operation';

export const saveFile = (blob, name) => {
    fileSaver.saveAs(blob, name);
}

export const EXPORT_REPORT_SUCCESS_MSG = `<b>Export has been successfully initiated</b><br></br>Preparing the file to download. You will be notified when the report is ready.`;
export const EXPORT_REPORT_ERROR_MSG = `<b>Export has not been generated</b><br></br>Please try again`;

export const convertUtc = (startF, startE) => {
    let endUTC = '';
    const startUTC = startF ? new Date(`${startF} 00:00:00`).toISOString() : '';
    if(startE) {
        let nextDay = new Date(startE);
        nextDay.setDate(nextDay.getDate() + 1);
        let tmp = nextDay.toISOString().substring(0, 10);
        endUTC = new Date(`${tmp} 00:00:00`).toISOString();
    }
    return {endUTC, startUTC};
}

export const isValidJSON = jsonString => {
    try {
        if(typeof jsonString === 'string')
            JSON.parse(jsonString);
        return true;
    } catch (error) {
        return false;
    }
}

/* Function to get current time zone of user */
export const getCurrentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const snackBarComponent = ({clientSnack, duration, anchorigin, snackmessage, snackbarhandleClose}) => {
    return(
        <>
        <Snackbar
            anchorOrigin={{...anchorigin}}
            open={clientSnack} autoHideDuration={duration}
            onClose={()=> snackbarhandleClose()}
            ContentProps={{ 'aria-describedby': 'genmessage-id'}}
            message={<span id="genmessage-id">{snackmessage}</span>}
        />
        </>
    );
}

const snkBarContStyle = {backgroundColor:'white', color:'black', fontSize: '14px', fontWeight: 700};

export const customSnackBar = ({ isCustSnack, snackCustHandClose, custAnchOrigin, custDuration, custSnkMsg, classes}) => {
    return (
        <Snackbar
            anchorOrigin={{ ...custAnchOrigin }}
            open={isCustSnack}
            onClose={() => snackCustHandClose()}
            ContentProps={{ 'aria-describedby': 'custsnmessage-id' }}
            autoHideDuration={custDuration}
            message={<span id="message-id" dangerouslySetInnerHTML={{ __html: custSnkMsg }} />}
            action={[
                <IconButton
                    key="close" aria-label="Close"
                    color="inherit" className={classes.close}
                    onClick={() => snackCustHandClose()}
                > <CloseSnkIcon /></IconButton>
            ]}
        >
            <SnackbarContent style={{...snkBarContStyle}} message={<span id="custmessage-id" dangerouslySetInnerHTML={{ __html: custSnkMsg }} />} />
        </Snackbar>
    )
}

export const millisToMinutesAndSeconds = millis => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

export const objectToCsvGeneric = (gendata, csvrows=[]) => {
    const csvGenRows = csvrows;
    const headersGen = Object.keys(gendata[0]);
    csvGenRows.push(headersGen.join(','));
    csvGenRows.push(" ");

    for (const row of gendata) {
        const valGen = headersGen.map(genRow=>{
            const escapedGen = (''+row[genRow]).replace(/"/g, '\\"');
            return `"${escapedGen}"`
        });
        csvGenRows.push(valGen.join(','));
    }
    return csvGenRows.join('\n');
}

export const downloadGenericCsv = (downloadData, fileGenericName) => {
    const blobGeneric = new Blob([downloadData], {type: 'text/csv'});
    const urlGeneric = window.URL.createObjectURL(blobGeneric);
    const anchorGeneric = document.createElement('a');
    anchorGeneric.setAttribute('hidden','');
    anchorGeneric.setAttribute('href', urlGeneric);
    anchorGeneric.setAttribute('download',`${fileGenericName}.csv`);
    document.body.appendChild(anchorGeneric);
    anchorGeneric.click();
    document.body.removeChild(anchorGeneric);
}

export function setStateSync(genstate, gencontext) {
    return new Promise((genresolve) => {
        gencontext.setState(genstate, genresolve)
    });
}

export const platformHrThemeColor = 'rgb(45, 184, 194)';

export const disableHtmlElement = {pointerEvents: 'none', opacity: 0.5};


export const descendingComparator = function(a, b, orderBy) {
    if(typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
      } else {
          if (b[orderBy] < a[orderBy]) {
              return -1;
          }
          if (b[orderBy] > a[orderBy]) {
              return 1;
          }
      }
      return 0;
  }
  
export const getComparator = function(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
export const stableSort = function(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

export const todayDate = moment(new Date()).format("YYYY-MM-DD");

export const searchTodayDateConvertToUTC = selectedDate => {
    const startUTC = new Date(`${selectedDate} 00:00:00`).toISOString();
    const endTmpUtc = new Date(selectedDate);
    endTmpUtc.setDate(endTmpUtc.getDate() + 1);
    const dateOne = endTmpUtc.toISOString().substring(0, 10);
    const endUTC = new Date(`${dateOne} 00:00:00`).toISOString();
    return {startUTC, endUTC};
}

export const convertGeneralRangeUtc = (startGenF, startGenE) => {
    let genEnd = '';
    const startGen = startGenF ? new Date(`${startGenF} 00:00:00`).toISOString() : '';
    if(startGenE) {
        let nextGenDay = new Date(startGenE);
        nextGenDay.setDate(nextGenDay.getDate() + 1);
        let tmpGen = nextGenDay.toISOString().substring(0, 10);
        genEnd = new Date(`${tmpGen} 00:00:00`).toISOString();
    }
    return {startGen, genEnd};
}

export const lastNoOfDays = (noOfDays, key) => {
    const lastNoDay = new Date();
    lastNoDay.setDate(lastNoDay.getDate() - noOfDays);
    const utcLast = lastNoDay.toISOString().substring(0, 10);
    const lastDays = new Date(`${utcLast} 00:00:00`).toISOString();
    return {[key] : {$gte: lastDays}};
}

export const statusProgress = (loading, color, width, height) => {
    return (
        <Fade
            in={loading}
            style={{  color, zIndex: 999}} unmountOnExit
        >
            <CircularProgress style={{width, height}}/>
        </Fade>
    )
}

// Added Generic Loading Progress bar from ticket id 5180
export const genericLoadingProgress = ({load, genStyle, circularStyle}) => {
    return (
        <Fade in={load} style={{...genStyle}} unmountOnExit>
            <CircularProgress style={{...circularStyle}}/>
        </Fade>
    )
}

export const loadingIndicator = loading => {
    return (
        <Fade
            in={loading}
            style={{
                color: platformHrThemeColor,
                position: 'fixed',
                top: '50vh',
                left: '55%',
                zIndex: 999
            }}
            unmountOnExit
            >
            <CircularProgress style={{width:'70px',height:'70px'}}/>
        </Fade>
    )
}

export const hideHorizontalScrollBar = {overflow: 'hidden auto'};

export const stickColumnTable = {
    top: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',                    
    backgroundColor: platformHrThemeColor,
    color:'#FFFFFF'
}

export const dateFormat = {
    format: value => {
    if(typeof value === 'string')
        return value.substring(0, 10);
    else 
        return moment(value).format('YYYY-MM-DD')
}
};

export const buttonStyle = {color:'#FFFFFF', backgroundColor: platformHrThemeColor, cursor:'pointer'};

export const timeZoneOffset = new Date().getTimezoneOffset();

export const GenDialog = ({openGenDialog, closeGenDialog, diagcontent, diagtitle, takeNextAction, classes, disableYes}) => {
    const disabGenYes = disableYes ? {...disableHtmlElement} : {};
    return(
    <Dialog
        open={openGenDialog} onClose={closeGenDialog}
        disableBackdropClick disableEscapeKeyDown
        aria-labelledby="alert-dialog-genlabel-title" aria-describedby="alert-dialog-gen-description"
    >
            <DialogTitle id="alert-dialog-genid-title">{diagtitle}</DialogTitle>
            <DialogContent><DialogContentText id="alert-dialog-description">{diagcontent}</DialogContentText></DialogContent>
            <DialogActions> 
                <Button onClick={closeGenDialog} variant="contained" color="primary" autoFocus> Cancel </Button>
                <Button onClick={takeNextAction} variant="contained" color="secondary" className={classes.button} style={{...disabGenYes}}> Yes </Button>
            </DialogActions>
    </Dialog>);
}

/** START: User Story 5012: Add Quarter field to bankRecLedger */

export const getQuarterDate = (year, quarter) => {
    let modifyQuarterdate = '';
    switch (quarter) {
        case 'Q1': modifyQuarterdate = `${year}-03-31`; break;
        case 'Q2': modifyQuarterdate = `${year}-06-30`; break;
        case 'Q3': modifyQuarterdate = `${year}-09-30`; break;
        case 'Q4': modifyQuarterdate = `${year}-12-31`; break;
    }
    return modifyQuarterdate;
}

export const getCurrentQuarter = currQuart => {
    let curquar = '';
    let switchYear = typeof currQuart == 'string' ? currQuart.slice(0, 4) : '';
    if (currQuart >= `${switchYear}-01-01` && currQuart <= `${switchYear}-03-31`)
        curquar = `${switchYear}-Q1`;
    else if (currQuart >= `${switchYear}-04-01` && currQuart <= `${switchYear}-06-30`)
        curquar = `${switchYear}-Q2`;
    else if (currQuart >= `${switchYear}-07-01` && currQuart <= `${switchYear}-09-30`)
        curquar = `${switchYear}-Q3`;
    else {
        if (currQuart >= `${switchYear}-10-01` && currQuart <= `${switchYear}-12-31`)
            curquar = `${switchYear}-Q4`;
    }
    return curquar;
}

export const createQuarterDate = editQuarterSel => {
    let [getYear, getQuar] = editQuarterSel ? editQuarterSel.split('-') : ['',''];
    getQuar = typeof getQuar === 'string' ? getQuar.trim() : '';
    getYear = typeof getYear === 'string' ? getYear.trim() : '';
    let createQuarter = '';
    if (getYear && editQuarterSel && getQuar) {
        createQuarter = getQuarterDate(getYear, getQuar);
    }
    const quarterActivity = `${editQuarterSel}`;
    return { createQuarter, quarterActivity };
}

export const dateFormat_YYYYMMDD = (date = '') => {
    let rd = date ? new Date(date) : new Date();
    rd.setDate(rd.getDate());
    const month = rd.getMonth() < 9 ? '0' + (rd.getMonth() + 1) : rd.getMonth() + 1;
    const day = rd.getDate() < 10 ? '0' + rd.getDate() : rd.getDate();
    return `${rd.getFullYear()}-${month}-${day}`;
}

export const getDynamicListOfQuarter = () => {
    const currYear = new Date().getFullYear();
    const quartArr = ['Q1', 'Q2', 'Q3', 'Q4'];
    const dynamicQuart = [];
    for (let i = 2018; i <= currYear; i++) {
        if (currYear - i !== 0)
            quartArr.forEach(s => dynamicQuart.unshift(`${i}-${s}`));
        else {
            const currDate = dateFormat_YYYYMMDD();
            const quart = getCurrentQuarter(currDate);
            if (typeof quart === 'string' && quart) {
                const [, secondq] = quart.indexOf('-') !== -1 ? quart.split('-') : ['', ''];
                const indQuart = secondq ? quartArr.findIndex(a => typeof secondq === 'string' && a === secondq.trim()) : -1;
                if (indQuart !== -1) {
                    quartArr.slice(0, indQuart + 1).forEach(s => dynamicQuart.unshift(`${i}-${s}`));
                }
                const latestQ = dynamicQuart.length ? dynamicQuart[0] : '';
                if (latestQ) {
                    const [nextFour, nextFourSecond] = latestQ.indexOf('-') !== -1 ? latestQ.split('-') : ['', ''];
                    if (typeof nextFour === 'string' && nextFour.trim()) {
                        if (typeof nextFourSecond === 'string' && nextFourSecond.trim()) {
                            const getQuarInd = quartArr.findIndex(qn => qn === nextFourSecond.trim());
                            if (getQuarInd !== -1) {
                                const next = getQuarInd + 1;
                                if (next >= 0) {
                                    quartArr.slice(getQuarInd + 1).forEach(ns => dynamicQuart.unshift(`${nextFour}-${ns}`));
                                    quartArr.slice(0, getQuarInd + 1).forEach(ns => dynamicQuart.unshift(`${currYear + 1}-${ns}`));
                                    dynamicQuart.unshift('Current Quarter');
                                }

                            }
                        }
                    }
                }
            }
        }
    }
    return { dynamicQuart };
}

/** END: User Story 5012: Add Quarter field to bankRecLedger */

/** START: Bug 5329: Kanban search filter is not working as expected */

export const getAllObjectValuesWithKeys = (obj, keysToInclude, keysInSpecificArrays = {}, keysInSpecificObjects = {}) => {
    const result = [];
    const helper = obj => {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (keysToInclude.includes(key)) {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        if (typeof item === 'object' && item !== null)
                            helper(item);
                        else
                            result.push(item);
                    });
                } else if (typeof value === 'object' && value !== null)
                    helper(value);
                else
                    result.push(value);
            } else if (Array.isArray(value) && keysInSpecificArrays[key]) {
                value.forEach(item => {
                    if (typeof item === 'object' && item !== null) {
                        Object.keys(item).forEach(subKey => {
                            if (keysInSpecificArrays[key].includes(subKey)) {
                                const subValue = item[subKey];
                                if (typeof subValue === 'object' && subValue !== null)
                                    helper(subValue);
                                else
                                    result.push(subValue);
                            }
                        });
                    }
                });
            } else if (typeof value === 'object' && value !== null && keysInSpecificObjects[key]) {
                Object.keys(value).forEach(subKey => {
                    if (keysInSpecificObjects[key].includes(subKey)) {
                        const subValue = value[subKey];
                        if (typeof subValue === 'object' && subValue !== null)
                            helper(subValue);
                        else
                            result.push(subValue);
                    }
                });
            }
        });
    };

    helper(obj);
    return result;
}
  
/** END: Bug 5329: Kanban search filter is not working as expected */