import {FuseLoadable} from '@fuse';

export const RPReconcilliationDashboardConfig  = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/app/rPRec/rPRecD',
            component: FuseLoadable({
                loader: () => import('./RPReconciliationDashboardApp')
              
            })
        },
    ]
};

