import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    withStyles
} from '@material-ui/core';
import Apiconfig from "configurations";
import axios from 'axios';

const styles = theme => ({
    root: {
        position:'relative', left:'10px'
    }
});

class StatusPage extends React.Component {

    state = {
        backendStatusPage: {
            api: 'unknown',
            mongodb: 'unknown'
        }
    }

    componentDidMount() {
        this.backendApiMethod();
        this.queryMongoDb();
        // this.recApiMethod();
    }

    backendApiMethod = async () => {
        try {
            const backapi = await axios.get(Apiconfig.baseUrl);
            if(backapi.status === 200) {
                this.setState(prevState=>({
                    backendStatusPage: {...prevState.backendStatusPage, api: 'pass'},
                }));
            }
        }
        catch(backapiErr) {
            console.error(backapiErr);
            this.setState(errorState=>({
                backendStatusPage: {...errorState.backendStatusPage, api: 'fail'},
            }));
        }
    }

    queryMongoDb = async () => {
        try {
            const backapi = await axios.get('/api/healthCheck/queryMongo');
            if(backapi.status === 200) {
                this.setState(prevState=>({
                    backendStatusPage: {...prevState.backendStatusPage, mongodb: 'pass'},
                }));
            }
        }
        catch(mongoErr) {
            console.error(mongoErr);
            this.setState(mongoerrorState=>({
                backendStatusPage: {...mongoerrorState.backendStatusPage, mongodb: 'fail'},
            }));
        }
    }

    recApiMethod = async () => {
        try {
            const recapiRes = await axios.get(Apiconfig.recApi, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            if(recapiRes.status === 200) {
                this.setState({recStatusPage: true});
            }
        }
        catch(recapiErr) {
            console.error(recapiErr);
        }
    }

    render() {
        const { classes } = this.props;
        const {backendStatusPage:{api, mongodb}} = this.state;
        const jsonObj = {status:[{api, mongodb}]};
        return (
            <>
            <div className={classes.root}>
                <><pre>{JSON.stringify(jsonObj,null,2)}</pre></>
            </div>
        </>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(StatusPage));
