import axios from "axios";
import jwtDecode from "jwt-decode";
import FuseUtils from "@fuse/FuseUtils";
import Apiconfig from "configurations";

axios.defaults.baseURL = Apiconfig.baseUrl;

axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (
      window.location.pathname !== "/login" ||
      window.location.pathname !== "/"
    ) {
      //  let mytoken = setlocal('token');
      let mytoken = localStorage.getItem("token");
      /** START: Bug 5152: Data crossing tenants */
      const sessionInfo = sessionStorage.getItem("sessionInfo")
      let orgId = typeof sessionInfo === 'string' && sessionInfo.trim() ? parseInt(sessionInfo.trim()) : null;
      /** END: Bug 5152: Data crossing tenants */
      let socket = window.socketInfo;
      if (mytoken) {
        config.headers["Authorization"] = "Bearer " + mytoken;
        config.headers["custom"] = orgId ? orgId : null;
        config.headers["socket"] = socket;
      } else {
        localStorage.removeItem("token");
      }
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

//Response Interceptor
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response && error.response.data && error.response.data.status === 401) {
        const errArr = error.response.data.error;
        if(errArr && errArr.length) {
          const {refresh_token, access_token, code, message} = errArr[0];
          if(refresh_token === 'refresh') {
            localStorage.setItem("token", access_token);
          } else if(refresh_token === 'expired' 
            || (code === 'invalid_token' && message === 'jwt expired')
            || (code === 'credentials_required' && message === 'No authorization token was found')) {
            localStorage.removeItem('token');
            window.location.pathname = '/login';
          }
        }
    }
    return Promise.reject(error);
  }
);

class jwtService extends FuseUtils.EventEmitter {
  init() {
    // this.setInterceptors();
    // this.reqInterceptors();
    // this.handleAuthentication();
  }

  setlocal = lcname => {
    const nameEQS = lcname + "=";
    let caKie = document.cookie.split(";");
    for (let i = 0; i < caKie.length; i++) {
      let pc = caKie[i];
      while (pc.charAt(0) === " ") pc = pc.substring(1, pc.length);
      if (pc.indexOf(nameEQS) === 0) return pc.substring(nameEQS.length, pc.length);
    }
    return null;
  };

  todo() {
    axios.interceptors.request.use(
      config => {
        if (window.location.pathname === "/app/rPRec/rPRecD") {
          let mytoken = this.setlocal("token");

          if (mytoken) {
            config.headers["Authorization"] = "Bearer " + mytoken;
          }
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  // handleAuthentication = () => {

  //     let access_token = this.getAccessToken();

  //     debugger;
  //     if (!access_token) {
  //         return;
  //     }

  //     if (this.isAuthTokenValid(token)) {
  //         this.setSession(token);
  //         this.emit('onAutoLogin', true);
  //     }
  //     else {
  //         this.setSession(null);
  //         this.emit('onAutoLogout', 'access_token expired');
  //     }
  // };

  createUser = data => {
    return new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then(response => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  // sign in with email and password

  // signInWithEmailAndPassword = (email, password) => {
  //     // var baseURL= 'http://104.208.219.81/`;
  //     debugger;
  //     var obj = { email: email, password: password }
  //     let encoded = btoa(JSON.stringify(obj));

  //     return new Promise((resolve, reject) => {

  //         axios.post('/api/auth/local', {
  //             data: encoded
  //         }).then(response => {
  //             if (response.data) {
  //                 var access_token = response.data.data.token;

  //                 this.setSession(access_token);
  //                 resolve(access_token);
  //             }
  //             else {
  //                 // let res = response;

  //                 reject(response.error);
  //             }
  //         });
  //     });
  // };

  // signInWithEmailAndPassword = (email, password) => {
  //     return new Promise((resolve, reject) => {
  //         axios.get('/api/auth', {
  //             data: {
  //                 email,
  //                 password
  //             }
  //         }).then(response => {
  //             if ( response.data.user )
  //             {
  //                 this.setSession(response.data.access_token);
  //                 resolve(response.data.user);
  //             }
  //             else
  //             {
  //                 reject(response.data.error);
  //             }
  //         });
  //     });
  // };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/auth/access-token", {
          data: {
            access_token: this.getAccessToken()
          }
        })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  updateUserData = user => {
    return axios.post("/api/auth/user/update", {
      user: user
    });
  };

  setSession = access_token => {
    if (access_token) {
      // debugger;
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem("token");
  };
}

const instance = new jwtService();

export default instance;
