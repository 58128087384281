import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse/index';
import { appsConfigs } from 'main/content/apps/appsConfigs';
import { pagesConfigs } from 'main/content/pages/pagesConfigs';
import { authRoleExamplesConfigs } from 'main/content/auth/authRoleExamplesConfigs';
import { UserInterfaceConfig } from 'main/content/user-interface/UserInterfaceConfig';
import { ComponentsConfig } from 'main/content/components/ComponentsConfig';
import { ComponentsThirdPartyConfig } from 'main/content/components-third-party/ComponentsThirdPartyConfig';
import { GettingStartedConfig } from 'main/content/getting-started/GettingStartedConfig';
import { LoginConfig } from 'main/login/LoginConfig';
import { MfaConfig } from 'main/login/MfaConfig';

import { ForgotPasswordConfig } from 'main/content/forgot-password/ForgotPasswordConfig';
import { RegisterConfig } from 'main/content/register/RegisterConfig';
import { LogoutConfig } from 'main/content/logout/LogoutConfig';
import { CallbackConfig } from 'main/content/callback/CallbackConfig';
import { StatusConfig } from 'main/content/statusPage/StatusConfig';
import { authRoles } from 'auth';
import _ from 'lodash';


const setAuth = configs => configs.map(config => _.merge({}, config, { auth: authRoles.admin }))

const routeConfigs =

    [
        ...setAuth([
            ...appsConfigs,
            ...pagesConfigs,
            ...authRoleExamplesConfigs,
            ComponentsConfig,
            ComponentsThirdPartyConfig,
            UserInterfaceConfig,
            GettingStartedConfig,
            LogoutConfig,

        ]),
        MfaConfig,
        LoginConfig,
        ForgotPasswordConfig,
        RegisterConfig,
        CallbackConfig,
        StatusConfig
    ]

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/login" />
    },
    {
        component: () => <Redirect to="/pages/errors/error-404" />
    }
];
