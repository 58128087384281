import { FuseLoadable } from '@fuse';

export const RPReconcilliationWorkflowConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: "/app/rPRec/rPRecWf",
            component: FuseLoadable({
                loader: () => import('./Mainworkflow')

            })
        },




    ]
};

